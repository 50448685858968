/* #tmpl_content, #tmpl_contentMenu_bar{
  padding-top: 70px !important;
} */

.tmpl_displayOff{
  display: none;
}

/* @media screen and (max-width: 640px) {
  #tmpl_content {
    padding-top: 64px;
  }
} */

/* tmplcontentの上にtmpl_contentMenu_barがある場合はtmplcontentのpadding-topは空けないようにする */
/* #tms */

/* tmpl_contentMenu_barの上にtmplcontentがある場合はtmpl_contentMenu_barのpadding-topは空けないようにする */
/* #tmpl_content #tmpl_contentMenu_bar {
  padding-top: 0 !important;
} */
#tmpl-header {
  margin: 0;
  padding: 0;
  /* visibility: hidden; */
}

body {
  width: 100%;
  margin: 0;
}

#tmpl-header {
  @include regular-font();
  margin: 0;
  padding: 0;
}

#tmpl-header .sp {
  display: none;
}

#tmpl-header {
  width: 100% !important;
  /* ページによってmax-width: 1190pxが指定されているため対策 */
  max-width: none !important;
  position: relative;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  z-index: 999999;
  transition: 0.64s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: transform;
}

#tmpl-header.is-hide {
  transform: translate(0, -100%);
}

#tmpl-header .tmpl-header_inner {
  width: 100%;
  max-width: 1190px;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

/* バツボタン */
#tmpl-header .tmpl-headerClose {
  display: none;
}

#tmpl-header a {
  text-decoration: none !important;
}

#tmpl-header .tmpl-header_head {
  display: flex;
  align-items: center;
  width: 100%;
  height:70px;
  //min-height: 70px;
  margin: 0;
  padding: 0;
  background: #000;
  border-bottom: 1px solid #363636;
  position: relative;
  box-sizing: border-box;
}

#tmpl-header .tmpl-header_logo {
  width: 108px;
  position: relative;
  z-index: 10;
  margin: 0;
  padding: 0;
}
#tmpl-header .tmpl-header_logo > a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 24px 0;
}
#tmpl-header .tmpl-header_logo img {
  width: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  transition: unset;
}

#tmpl-header .tmpl-headerHamburger {
  display: none;
}

#tmpl-header .tmpl-headerNavItem_label{
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background-color: #000;
  line-height: 1;
  padding: 27px 15px;
  cursor: pointer;
  position: relative;
  margin: 0 !important;
}

#tmpl-header .tmpl-headerNavItem_home {
  @include bold-font();
}

#tmpl-header .tmpl-headerNavItem_name {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

#tmpl-header .tmpl-headerNavItem.-contact {
  margin: 0;
  padding: 0;
  padding-bottom: 2px;
  position: relative;
}
#tmpl-header .tmpl-headerNavItem.-contact .absoluteList {
  padding: 0;
}
#tmpl-header .tmpl-headerNavItem.-contact .absoluteList li ::marker{
  display: none;
}

#tmpl-header .tmpl-headerNavItem.-contact .absoluteList .contactLink {
  background-color: #000;
}

/* ----------------
  DropDown
---------------- */
#tmpl-header .tmpl-headerNav_list {
  list-style-type: none !important;
  margin: 0;
  padding: 0;
}

#tmpl-header .tmpl-headerNavDropDownBg {
  width: 100%;
  height: 0;
  background: #000;
  position: absolute;
  left: 0;
  top: 70px;
  margin: 0;
  padding: 0;
  z-index: 999;
}

#tmpl-header .tmpl-headerNavDropDown a {
  color: #fff !important;
}

#tmpl-header #tmpl-headerNav_list .tmpl-headerNavDropDown_toCategoryTop {
  display: block;
  @include regular-font();
  font-size: 18px;
  //font-weight: 400;
  line-height: 1;
  margin-bottom: 10px;
  position: relative;
  text-align: left;
  background: none;
  margin: 0;
  padding: 0;
}

#tmpl-header #tmpl-headerNav_list .tmpl-headerNavDropDown_toCategoryTop span {
  color: #fff;
  transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#tmpl-header .tmpl-headerNavDropDown_toCategoryTop a {
  display: block;
  visibility: hidden;
  width: 100%;
  height: 100%;
  // padding: 0 0 22px;
  margin-top: 22px;
}
#tmpl-header .tmpl-headerNavDropDown_toCategoryTop:first-child a{
  margin-top: 0;
}

#tmpl-header .tmpl-headerNavDropDown_inner {
  width: 100%;
  padding: 30px 40px;
  box-sizing: border-box;
  background: #2B2B2B;
}

#tmpl-header .tmpl-headerNavDropDown_txtContainer {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#tmpl-header .tmpl-headerNavDropDown_list {
  padding: 0 !important;
  // margin: 0 !important;
  margin-top: 10px;
  list-style-type: none !important;
}

#tmpl-header .tmpl-headerNavDropDownItem {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
}
#tmpl-header .in_list .tmpl-headerNavDropDownItem {
  font-size: 13px;
}
#tmpl-header .tmpl-headerNavDropDownItem a {
  display: block;
  visibility: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 10px 40px 10px 0;
  box-sizing: border-box;
}

















/* アクティブ後 */
#tmpl-header .tmpl-headerNavItem.is-open .tmpl-headerNavDropDown {
  height: auto;
  opacity: 1;
  margin: 0;
  padding: 0;
  transition: 0.48s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: 0.08s;
  transition-property: opacity;
  will-change: height;
  transition: height 0.48s cubic-bezier(0.165, 0.84, 0.44, 1);
  visibility: visible;
}

#tmpl-header p.tmpl-headerNavDropDownItem_label,
#tmpl-header p.tmpl-headerNavDropDownItem_label_2 {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  color: #fff;
  transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
#tmpl-header p.tmpl-headerNavDropDownItem_labeltitle {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 20px 0 10px;
  color: #999999;
  font-size: 12px;
}

#tmpl-header .tmpl-headerNavDropDown_toCategoryTop a > span::after,
#tmpl-header .tmpl-headerNavDropDownItem_label::after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border: $global-nav-link-icon-border;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  margin: 0 0 1px 0.64em;
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: transform, margin;
}
#tmpl-header .tmpl-headerNavDropDownItem_label_2::after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border: $global-nav-link-icon-border;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  margin: 0 0 9px 0.64em;
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: transform, margin;
}
.headerNavDropDownItem_labeltitle {
  display: flex;
  align-items: center;
  padding: 0;
  color: #999999;
}

/* ----------------
  Search
---------------- */
#tmpl-header .tmpl-header_searchBtnContainer {
  width: 100%;
  max-width: 1190px;
  height: 70px;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  /* z-index: -10; */
  pointer-events: none;
}
#tmpl-header .tmpl-header_searchBtn {
  /* max-width: 58px; */
  width: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 10;
  pointer-events: all;
  outline: none;
  overflow: hidden;
  z-index: 100;
}
#tmpl-header .tmpl-header_searchBtn_name {
  background-color: #000;
}
#tmpl-header .tmpl-header_searchBtn > svg {
  position: absolute;
  pointer-events: none;
  /* left: 50%; */
  top: 50%;
  right: 0;
  margin: 0;
  padding: 0;
  transform: translateY(-50%);
  /* transform: translate(-50%, -50%); */
}
#tmpl-header .tmpl-header_searchBtn:hover > svg path,
#tmpl-header .tmpl-header_searchBtn:focus > svg path {
  fill: #4f83df;
}

/* 検索閉じるボタン普段は消えてる */
#tmpl-header .tmpl-header_searchCloseBtn {
  display: none;
  position: absolute;
  pointer-events: none;
  width: 16px;
  height: 16px;
  top: 25px;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 100;
}

#tmpl-header .tmpl-header_searchClose_line {
  position: absolute;
  width: 100%;
  height: 1px;
  background: #fff;
}

#tmpl-header .tmpl-header_searchClose_line.-line-first {
  top: 50%;
  left: 0;
  transform: translateY(-50%)rotate(45deg);
}

#tmpl-header .tmpl-header_searchClose_line.-line-last {
  top: 50%;
  right: 0;
  transform: translateY(-50%)rotate(-45deg);
}

#tmpl-header .tmpl-header_searchClose_name {
  font-size: 10px;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
}

#tmpl-header .header_searchBtn_name {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
}

#tmpl-header .tmpl-headerSearch {
  position: absolute;
  /* right: 6px;
  top: 26px; */
  right: 0px;
  top: 70px;
  z-index: 100;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: none;
}

#tmpl-header .tmpl-headerSearch input[type="submit"],
#tmpl-header .tmpl-headerSearch_ico {
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;
  background: #8c8c8c;
  border: none;
  outline: none;
  transition: 0.32s background ease-out;
}

#tmpl-header .tmpl-headerSearch input[type="submit"] {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

#tmpl-header .tmpl-headerSearch_ico {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  padding: 0;
  pointer-events: none;
}

#tmpl-header .tmpl-headerSearch_label {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

#tmpl-header .tmpl-headerSearch_input {
  display: block;
  width: 380px;
  height: 50px;
  border: none;
  margin: 0;
  padding: 10px;
  border-radius: 0;
  box-sizing: border-box;
  background-color: #FFF !important;
  font-size: 16px;
  float: left;
  outline: none;
}

#tmpl-header .tmpl-headerSearch_input::placeholder {
  color: #757575;
}
#tmpl-header .tmpl-headerSearch_input::-moz-placeholder {
  color: #757575;
  opacity: 1 !important;
}

#tmpl-header .tmpl-headerSearch_bg {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: -10;
}

/* スライド */
@keyframes zoom {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.08);
  }
}

//.swiper-wrapper {
//  overflow: hidden;
//}
//
//.swiper-slide img {
//  width: 100%;
//  height: auto;
//}

#tmpl-header .tmpl-header_searchClose {
  display: none;
}

#tmpl-header .tmpl-header_searchBtn_name {
  opacity: 0;
  color: #FFF;
  font-size: 12px;
  pointer-events: none;
}

#tmpl-header .tmpl-header_searchBtn:hover > .tmpl-header_searchBtn_name,
#tmpl-header .tmpl-header_searchBtn:focus > .tmpl-header_searchBtn_name {
  color: #4f83df;
}

#tmpl-header .tmpl-header_searchBtn:hover > .tmpl-header_searchCloseBtn > .tmpl-header_searchClose_line,
#tmpl-header .tmpl-header_searchBtn:focus > .tmpl-header_searchCloseBtn > .tmpl-header_searchClose_line {
  background: #4f83df;
}

/* ----------------------------------------
  SP
  breakpoint は 640px のみ
---------------------------------------- */




@media screen and (min-width: 641px) {

  .sp {
    display: none;
  }

  #tmpl-header {
    min-width: 1190px !important;
  }

  #tmpl-header .tmpl-headerNavDropDownBg {
    display: none;
  }

  #tmpl-header .tmpl-headerNav_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1190px;
    height: 70px;
    //min-height:70px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 0 140px 0 140px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  #tmpl-header .tmpl-headerNavItem {
    position: relative;
  }

  /* PCでは見れるようにする */
  #tmpl-header.sp-header .tmpl-headerNavItem_label,
  #tmpl-header.sp-header .tmpl-headerNavItem_home,
  #tmpl-header.sp-header .contactLink {
    visibility: visible !important;
  }

  // #tmpl-header .tmpl-headerNavDropDown_inner {
  //   padding: 24px 40px 36px;
  // }

  #tmpl-header .tmpl-headerNavItem.-contact {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0, -50%);
  }

  /* .tmpl-headerNavItem.is-open */
  #tmpl-header .tmpl-headerNavItem.is-open .tmpl-headerNavItem_label {
    background-color: #000;
  }

  // #tmpl-header .tmpl-headerNavItem.-contact::after {
  //   content: "";
  //   display: block;
  //   width: 1px;
  //   height: 20px;
  //   /* background: #696969; */
  //   background: #FFFFFF;
  //   position: absolute;
  //   right: 0;
  //   top: 50%;
  //   transform: translate(0, -50%);
  // }
  #tmpl-header .tmpl-headerNavItem.-contact .absoluteList {
    display: flex;
  }
  #tmpl-header .tmpl-headerNavItem.-contact .absoluteList li {
    margin-right: 15px;
  }
  #tmpl-header .tmpl-headerNavItem.-contact a {
    font-size: 11px;
    font-weight: 400;
    color: #fff;
  }

  #tmpl-header .tmpl-headerNavItem_label:hover,
  #tmpl-header .tmpl-headerNavItem_home:hover {
    color: #5E9DF4;
  }

  /* #tmpl-header .tmpl-headerNavDropDownItem:last-child a {
    padding-bottom: 0;
  } */

  // #tmpl-header .tmpl-headerNavDropDown_toCategoryTop a {
  //   padding: 12px 0 24px;
  // }

  // #tmpl-header .tmpl-headerNavDropDownItem a {
  //   padding: 0 0 24px;
  // }

  #tmpl-header .tmpl-headerNavDropDown a {
    transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  /* ページによってpタグにcolor等のスタイルが指定されているため上書きをしています */
  #tmpl-header .tmpl-headerNavDropDown a:hover,
  #tmpl-header .tmpl-headerNavDropDown a:hover > p,
  #tmpl-header .tmpl-headerNavDropDown a:hover > span,
  #tmpl-header .tmpl-headerNavDropDown a:focus,
  #tmpl-header .tmpl-headerNavDropDown a:focus > p,
  #tmpl-header .tmpl-headerNavDropDown a:focus > span {
    color: #5e9df4 !important;
    text-decoration: none;
    outline: none;
  }




  #tmpl-header .tmpl-headerNavDropDown_toCategoryTop:hover > a span::after,
  #tmpl-header
  .tmpl-headerNavDropDownItem:hover
  .tmpl-headerNavDropDownItem_label::after,
  #tmpl-header .tmpl-headerNavDropDown_toCategoryTop > a:focus span::after,
  #tmpl-header
  .tmpl-headerNavDropDownItem
  a:focus
  .tmpl-headerNavDropDownItem_label::after{
    border-color: #5e9df4;
    transform: translate(4px, 0) rotate(45deg);
    transition-duration: 0.24s;
  }

  #tmpl-header .tmpl-headerNavItem_label::after,
  #tmpl-header .tmpl-headerNavItem_home::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: #2d61bf;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    transition: width 0.64s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  #tmpl-header .tmpl-headerNavItem_label:hover::after,
  #tmpl-header .tmpl-headerNavItem_home:hover::after,
    /* .tmpl-headerNavItem_label:focus::after, */
  #tmpl-header .tmpl-headerNavItem.is-open .tmpl-headerNavItem_label::after,
  #tmpl-header .tmpl-headerNavItem.is-open .tmpl-headerNavItem_home::after {
    width: calc(100% - 30px);
    transition-duration: 0.32s;
  }

  #tmpl-header .tmpl-headerNavItem_home::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: #2d61bf;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    transition: width 0.64s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  #tmpl-header .tmpl-headerNavItem_home:hover::after,
    /* .tmpl-headerNavItem_label:focus::after, */
  #tmpl-header .tmpl-headerNavItem.is-open .tmpl-headerNavItem_home::after {
    width: calc(100% - 30px);
    transition-duration: 0.32s;
  }

  #tmpl-header .tmpl-headerNavItem.-contact a:hover,
  #tmpl-header .tmpl-headerNavItem.-contact a:focus {
    color: #4f83df;
    outline: none;
  }

  #tmpl-header .tmpl-headerNavDropDown {
    display: none;
    height: 0;
    background: #2B2B2B;
    margin: 0 auto;
    position: absolute;
    left: -13px;
    right: 0;
    top: 69px;
    box-sizing: border-box;
    opacity: 0;
  }

  /* それぞれ幅指定 */
  #tmpl-header .tmpl-headerNavDropDown.-product {
    min-width: 450px;
  }
  #tmpl-header .tmpl-headerNavDropDown.-about {
    min-width: 450px;
  }
  #tmpl-header .tmpl-headerNavDropDown.-tech {
    min-width: 450px;
  }
  #tmpl-header .tmpl-headerNavDropDown.-employ {
    min-width: 450px;
  }
  #tmpl-header .tmpl-headerNavDropDown.-sustainability {
    min-width: 450px;
  }
  #tmpl-header .tmpl-headerNavDropDown.-design {
    min-width: 343px;
  }
  #tmpl-header .tmpl-headerNavDropDown.-investor {
    min-width: 343px;
  }

  #tmpl-header .tmpl-headerNavItem.is-open .tmpl-headerNavDropDown {
    display: block;
  }

  #tmpl-header .tmpl-contentNavDropDown .tmpl-headerNavDropDown_txtContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  #tmpl-header .tmpl-contentNavDropDown .tmpl-headerNavDropDown_txtContainer .tmpl-headerNavDropDown_toCategoryTop {
    width: 100%;
  }

  #tmpl-header .tmpl-headerNavItem_label {
    position: relative;


  }

  /* 吹き出し */
  #tmpl-header .tmpl-headerNavItem_label::before {
    opacity: 0;
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 14px 14px 14px;
    border-color: transparent transparent #2B2B2B transparent;
  }

  #tmpl-header .is-open .tmpl-headerNavItem_label::before {
    opacity: 1;
  }

  #tmpl-header .tmpl-contentNavDropDown .tmpl-headerNavDropDown_list {
    width: 100%;
  }

  #tmpl-header .tmpl-contentNavDropDown.-tech .tmpl-headerNavDropDown_list {
    width: 100%;
  }

  #tmpl-header .tmpl-contentNavDropDown.-employ .tmpl-headerNavDropDown_list {
    width: 100%;
  }

  /* バツボタン */
  #tmpl-header .tmpl-headerClose {
    display: block;
    position: absolute;
    //top: 20px;
    //right: 20px;
    top: 34px;
    right: 34px;
    width: 12px;
    height: 12px;
  }
  #tmpl-header .tmpl-headerClose::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transform: translate(-50%, -50%)rotate(45deg);
  }
  #tmpl-header .tmpl-headerClose::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transform: translate(-50%, -50%)rotate(-45deg);
  }
  #tmpl-header .tmpl-headerClose:hover::before, #tmpl-header .tmpl-headerClose:hover::after, #tmpl-header .tmpl-headerClose:focus::before, #tmpl-header .tmpl-headerClose:focus::after {
    background-color: #5E9DF4;
  }
  #tmpl-header .tmpl-headerClose_coment {
    opacity: 0;
  }

  /* 関連コンテンツ */
  /* テクノロジー */


  #tmpl-header .tmpl-headerSearch input[type="submit"]:hover,
  #tmpl-header .tmpl-headerSearch input[type="submit"]:focus {
    background: #2d61bf;
  }

  /* Search */
  #tmpl-header .tmpl-headerSearch .tmpl-headerSearch_inner {
    transform: scale(0.64);
    transform-origin: right top;
    opacity: 0;
    transition: 0.48s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-property: transform, opacity;
  }
  #tmpl-header .tmpl-headerSearch.is-open .tmpl-headerSearch_inner {
    transform: scale(1);
    opacity: 1;
    transition-duration: 0.32s;
  }
}


@media screen and (min-width: 641px) and (max-width:1296px) {
  #tmpl-header .tmpl-headerNavDropDown.-tech {
    left:auto;
    right:0;
  }

  #tmpl-header .tmpl-headerNavDropDown.-employ {
    left:auto;
    right:0;
  }
  #tmpl-header .tmpl-headerNavDropDown.-sustainability {
    left:auto;
    right:0;
  }
  #tmpl-header .tmpl-headerNavDropDown.-design {
    left:auto;
    right:0;
  }
  #tmpl-header .tmpl-headerNavDropDown.-investor {
    left:auto;
    right:0;
  }
}


@media screen and (max-width: 640px) {
  #tmpl-header {
    /* overflow: hidden; */
  }
  #tmpl-header .tmpl-header_bg {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: opacity 0.48s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  #tmpl-header .tmpl-header.is-open .tmpl-header_bg {
    opacity: 0.65;
  }
  #tmpl-header .tmpl-header_head {
    height: 64px;
  }

  #tmpl-header .tmpl-header_logo {
    width: 96px;
    margin: 0 auto;
  }
  #tmpl-header .tmpl-header_logo a {
    padding: 22px 0;
  }

  #tmpl-header .tmpl-headerNav {
    position: absolute;
    z-index: 999;
    width: 100%;
    /* width: calc(100% - 60px);
    max-width: 280px; */
    height: 0;
    background: #000;
    overflow: auto;
    /* transform: translate(100%, 0); */
    float: right;
    transition: height .1s ease-in-out;
    will-change: height;
  }
  #tmpl-header .tmpl-headerHamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    position: absolute;
    right: 0;
    top: 0;
  }
  #tmpl-header .tmpl-headerHamburger_inner {
    width: 20px;
    height: 15px;
    position: relative;
    pointer-events: none;
  }
  #tmpl-header .tmpl-headerHamburger_line {
    display: block;
    width: 100%;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    transition: top 0.24s 0.16s, transform 0.16s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  #tmpl-header .tmpl-headerHamburger_line.-line-01 {
    top: 0;
    will-change: transform;
  }
  #tmpl-header .tmpl-headerHamburger_line.-line-02 {
    top: 50%;
    /* transform: translate(0, -50%) rotate3d(0, 0, 0); */
    transition: opacity 0.16s 0.24s;
    /* transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); */
    will-change: opacity;
  }
  #tmpl-header .tmpl-headerHamburger_line.-line-03 {
    top: calc(100% - 1px);
    will-change: transform;
  }
  #tmpl-header .tmpl-headerHamburger_name {
    font-size: 10px;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
  }

  #tmpl-header .tmpl-header_searchBtn {
    width: 58px;
    left: 0;
    right: auto;
  }

  #tmpl-header .tmpl-header_searchBtn > svg {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #tmpl-header .tmpl-headerNavItem {
    border-bottom: 1px solid #262626;
  }
  #tmpl-header .tmpl-headerNavItem.-contact .absoluteList li:first-child {
    border-bottom: 1px solid #262626;
  }

  #tmpl-header .tmpl-headerNavItem.-contact .absoluteList li:first-child:last-child {
    border-bottom: none;
  }

  #tmpl-header .tmpl-headerNavItem_label,
  #tmpl-header .tmpl-headerNavItem_home,
  #tmpl-header .tmpl-headerNavItem.-contact a {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    padding: 22px 26px;
  }
  /* スマホでフォーカスが合わないようにする */
  #tmpl-header.sp-header .tmpl-headerNavItem_label,
  #tmpl-header.sp-header .tmpl-headerNavItem_home,
  #tmpl-header.sp-header .contactLink {
    visibility: hidden;
  }
  #tmpl-header .tmpl-headerNavItem_label span,
  #tmpl-header .tmpl-headerNavItem_home span {
    /* display: none; */
    opacity: 0;
  }
  #tmpl-header .tmpl-headerNavItem_label::before,
  #tmpl-header .tmpl-headerNavItem_label::after {
    content: "";
    display: block;
    width: 13px;
    height: 1px;
    background: #b1b1b1;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(0, -50%);
    transition: 0.32s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-property: transform, opacity;
  }
  #tmpl-header .tmpl-headerNavItem_label::after {
    transform: translate(0, -50%) rotate(90deg);
  }

  #tmpl-header.sp-header .tmpl-headerNavItem_label:focus{
    border:1px solid blue;
    width:calc(100% - 2px);
    box-sizing: border-box;
  }

  #tmpl-header .tmpl-headerNavItem_name {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  /* DropDown */
  #tmpl-header .tmpl-headerNavDropDown {
    height: 0;
    overflow: hidden;
    transition: height .1s ease-in-out;
  }

  #tmpl-header .tmpl-headerNavDropDown_inner {
    width: 100%;
    flex-direction: column-reverse;
    padding: 0;
  }

  #tmpl-header .tmpl-headerNavDropDown_txtContainer {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px 0;
  }

  #tmpl-header .tmpl-headerNav_item.is-open .tmpl-headerNavDropDown_txtContainer {
    background: #262626;
  }

  #tmpl-header .tmpl-headerNavDropDown_txtContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 52px);
    height: 1px;
    background-color: #363636;
  }







  #tmpl-header .tmpl-headerNavDropDown_list {
    display: block;
    height: auto;
    margin-top: 0;
  }
  #tmpl-header .tmpl-headerNavDropDown_toCategoryTop {
    margin: 0;
  }
  #tmpl-header .tmpl-headerNavDropDown_toCategoryTop a {
    font-size: 16px;
    line-height: 1.5;
    padding: 10px 26px 10px 26px;
    margin-top: 15px;
    box-sizing: border-box;
  }
  #tmpl-header .tmpl-headerNavDropDown_toCategoryTop span > span {
    display: none;
  }
  #tmpl-header .tmpl-headerNavDropDown_toCategoryTop::after {
    content: none;
  }
  #tmpl-header .tmpl-headerNavDropDownItem {
    width: 100%;
    font-size: 14px;
    line-height: 1.5;
  }
  #tmpl-header .tmpl-headerNavDropDownItem-dummy {
    display: none;
  }
  #tmpl-header .tmpl-headerNavDropDownItem a {
    display: inline-block;
    padding: 10px 26px 10px 26px;
    box-sizing: border-box;
  }
  #tmpl-header .tmpl-headerNavDropDownItem a {
    position: relative;
  }

  #tmpl-header .tmpl-headerNavDropDown_toCategoryTop a > span::after,
  #tmpl-header .tmpl-headerNavDropDownItem_label::after {
    transform: translate(0, -50%) rotate(45deg);
    margin: 0 0 0 12px;
  }
  #tmpl-header .tmpl-headerNavDropDown_toCategoryTop .tmpl-headerNavDropDownItem_label::after {
    margin: 8px 0 0 12px;
  }
  #tmpl-header .tmpl-headerNavDropDownItem_label::after {
    margin: 5px 0 0 12px;
  }
  #tmpl-header .in_list .tmpl-headerNavDropDownItem_label::after {
    margin: 5px 0 0 12px;
  }

  /* open */
  #tmpl-header .tmpl-header_head.is-open .tmpl-headerHamburger_line {
    top: 50%;
    transition: top 0.16s, transform 0.24s 0.16s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  #tmpl-header .tmpl-header_head.is-open .tmpl-headerHamburger_line.-line-01 {
    transform: rotate(45deg);
  }
  #tmpl-header .tmpl-header_head.is-open .tmpl-headerHamburger_line.-line-02 {
    opacity: 0;
    /* transition: opacity 0.16s cubic-bezier(0.165, 0.84, 0.44, 1); */
  }
  #tmpl-header .tmpl-header_head.is-open .tmpl-headerHamburger_line.-line-03 {
    transform: rotate(-45deg);
  }

  /* headerNavItem open */
  #tmpl-header .tmpl-headerNavItem.is-open .tmpl-headerNavItem_label::before {
    transform: translate(0, -50%) rotate(90deg);
    opacity: 0;
  }
  #tmpl-header .tmpl-headerNavItem.is-open .tmpl-headerNavItem_label::after {
    transform: translate(0, -50%) rotate(180deg);
  }

  /* Search */
  #tmpl-header .tmpl-header_searchBtnContainer {
    height: 64px;
  }
  #tmpl-header .tmpl-headerSearch {
    width: 100%;
    height: 100vh;
    right: 0;
    top: 0;
  }
  #tmpl-header .tmpl-headerSearch_inner {
    width: 100%;
    height: 64px;
    background: #fff;
  }
  #tmpl-header .tmpl-headerSearch_input {
    /* input のフォントサイズをscaleで小さくする. */
    width: calc(115% - 64px);
    /* height: 54.16px; */
    background: #efefef !important;
    margin: 10px 0 0 54px;
    transform: scale(0.8125);
    transform-origin: left top;
  }
  #tmpl-header .tmpl-headerSearch_input::placeholder {
    color: #696969;
    opacity: 1 !important;
  }
  #tmpl-header .tmpl-headerSearch_input::-moz-placeholder {
    color: #696969;
    opacity: 1 !important;
  }
  #tmpl-header .tmpl-headerSearch input[type="submit"],
  #tmpl-header .tmpl-headerSearch_ico {
    width: 44px;
    height: 44px;
    background: #8c8c8c;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: 0.32s background ease-out;
  }
  #tmpl-header .tmpl-headerSearch_ico {
    background: none;
  }
  #tmpl-header .tmpl-headerSearch input[type="submit"]:focus {
    background: #2d61bf !important;
  }
  #tmpl-header .tmpl-header_searchClose {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 64px;
    position: absolute;
    left: 0;
    top: 0;
  }
  #tmpl-header .tmpl-header_searchClose::after {
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    border: solid #707070;
    border-width: 0 0 1px 1px;
    transform: rotate(45deg);
  }
  #tmpl-header .tmpl-header_searchClose.onfocus::after {
    border-color: #2d61bf;
    border-width: 0 0 2px 2px;
  }
  #tmpl-header .tmpl-header_searchClose span {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 10px;
    opacity: 0;
  }

  #tmpl-header .tmpl-header_searchBtn_name {
    opacity: 0;
  }

  /* スマホでは閉じるアイコン必要ないので絶対に表示させない */
  #tmpl-header .tmpl-header_searchCloseBtn {
    display: none !important;
  }
}





@media screen and (max-width: 374px) {
  #tmpl-header .tmpl-headerSearch_input {
    font-size: 13px;

    input::placeholder {
      font-size: 12px;
    }
  }
}

#tmpl-header .tmpl-headerNav_pauseController {
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 100;
}
#tmpl-header .tmpl-headerNav_pauseController_btn {
  width: 32px !important;
  height: 32px !important;
  cursor: pointer;
  padding: 10px 0;
  box-sizing: border-box;
  background: #000;
  border-radius: 50%;
}
/* .tmpl-headerNav_pauseController_btn.is-active {
  display: block !important;
  transition: 0.24s ease-out;
  transition-property: background;
} */
#tmpl-header .tmpl-headerNav_pauseController_btn rect,
#tmpl-header .tmpl-headerNav_pauseController_btn path {
  fill: #fff;
  transition: 0.24s ease-out;
  transition-property: fill;
}
#tmpl-header .tmpl-headerNav_pauseController_btn:hover,
#tmpl-header .tmpl-headerNav_pauseController_btn:focus {
  background: #fff;
}
#tmpl-header .tmpl-headerNav_pauseController_btn:hover rect,
#tmpl-header .tmpl-headerNav_pauseController_btn:hover path,
#tmpl-header .tmpl-headerNav_pauseController_btn:focus rect,
#tmpl-header .tmpl-headerNav_pauseController_btn:focus path {
  fill: #2d61bf;
}
#tmpl-header .tmpl-headerNav_pauseController_btn svg {
  display: block;
  margin: 0 auto;
}
#tmpl-header .tmpl-headerNav_pauseController_btn.-pause svg {
  width: 12px;
  height: 12px;
}
#tmpl-header .tmpl-headerNav_pauseController_btn.-play svg {
  width: 12px;
  height: 12px;
  transform: translate(2px, 0);
}
#tmpl-header .tmpl-headerNav_pauseController_btn .no_button {
  font-size: 10px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#tmpl-header .control_btn {
  display: none !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#tmpl-header .control_btn.is-active {
  display: block !important;
  transition: 0.24s ease-out;
  transition-property: background;
}

//.swiper-aboutsony .swiper-slide,
//.swiper-design .swiper-slide,
//.swiper-ir .swiper-slide {
//  opacity: 1 !important;
//}

@media print {
  #tmpl-header {
    width: 100%;
    -webkit-print-color-adjust: exact;
    background: #000 !important;
    padding: 0 20px;
  }
  #tmpl-header .tmpl-headerNav_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1190px;
    height: 70px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 0 0 0 140px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  #tmpl-header .tmpl-headerNavDropDown,
  #tmpl-header .tmpl-headerNavItem.-contact,
  #tmpl-header .tmpl-header_searchBtnContainer {
    display: none !important;
  }
}

/* タッチデバイスだとアウトラインをなくす */
/* .touch a {
  outline: none !important;
} */

a {
  -webkit-tap-highlight-color:rgba(0,0,0,0) !important;
}

.font17 {
  font-size: 17px !important;
}

#tmpl-header p.tmpl-headerNavDropDownItem_label_2 {
  color: #fff;
  // padding-bottom: 24px;
  font-size: 14px;
  line-height: 25px;
  align-items: flex-end;

}
#tmpl-header .in_list {
  margin-left: 13px;
  padding: 0;
}
#tmpl-header .in_list li::marker {
  content: none;
}
#tmpl-header .in_list .tmpl-headerNavDropDownItem > a {
  padding: 5px 40px 5px 0;
}
#tmpl-header .in_list li a .tmpl-headerNavDropDownItem_label {
  position: relative;
}
#tmpl-header .in_list li a .tmpl-headerNavDropDownItem_label::before {
  content: '';
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 1px #fff;
  border-right: solid 1px #fff;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8px;
  margin-left: -12px;
  transform: rotate(180deg);
}
// #tmpl-header .in_list li:first-child a {
//   padding-bottom: 16px;
// }
#tmpl-header .in_list li a{
  margin-left: 10px;
}
@media screen and (max-width: 640px) {
  // #tmpl-header p.tmpl-headerNavDropDownItem_label_2 {
  //   padding: 14px 26px 14px 51px;
  // }
  #tmpl-header .in_list,
  .in_list li a{
    margin-left: 0px;
  }
  #tmpl-header .in_list li:first-child a {
    //padding-bottom: 0;
  }
  #tmpl-header .in_list .tmpl-headerNavDropDownItem > a {
    margin-left: 0;
    font-size: 12px;
    padding: 10px 26px 10px 51px;
  }
  #tmpl-header p.tmpl-headerNavDropDownItem_labeltitle {
    margin: 10px 26px 10px 26px;
    font-size: 12px;
  }
}



.tmpl-header button.mf_finder_searchBox_submit{
  background-color:#333;

  &:hover{
    background-color:#757575;
  }
}