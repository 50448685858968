/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

.tmpl-header div, .tmpl-header span, .tmpl-header object, .tmpl-header iframe,
.tmpl-header h1, .tmpl-header h2, .tmpl-header h3, .tmpl-header h4, .tmpl-header h5, .tmpl-header h6, .tmpl-header p, .tmpl-header blockquote, .tmpl-header pre,
.tmpl-header abbr, .tmpl-header address, .tmpl-header cite, .tmpl-header code,
.tmpl-header del, .tmpl-header dfn, .tmpl-header em, .tmpl-header img, .tmpl-header ins, .tmpl-header kbd, .tmpl-header q, .tmpl-header samp,
.tmpl-header small, .tmpl-header strong, .tmpl-header sub, .tmpl-header sup, .tmpl-header var,
.tmpl-header b, .tmpl-header i,
.tmpl-header dl, .tmpl-header dt, .tmpl-header dd, .tmpl-header ol, .tmpl-header ul, .tmpl-header li,
.tmpl-header fieldset, .tmpl-header form, .tmpl-header label, .tmpl-header legend,
.tmpl-header table, .tmpl-header caption, .tmpl-header tbody, .tmpl-header tfoot, .tmpl-header thead, .tmpl-header tr, .tmpl-header th, .tmpl-header td,
.tmpl-header article, .tmpl-header aside, .tmpl-header canvas, .tmpl-header details, .tmpl-header figcaption, .tmpl-header figure,
.tmpl-header footer, .tmpl-header header, .tmpl-header hgroup, .tmpl-header menu, .tmpl-header nav, .tmpl-header section, .tmpl-header summary,
.tmpl-header time, .tmpl-header mark, .tmpl-header audio, .tmpl-header video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

.tmpl-header {
  line-height:1;
}

.tmpl-header article,.tmpl-header aside,.tmpl-header details,.tmpl-header figcaption,.tmpl-header figure,
.tmpl-header footer,.tmpl-header header,.tmpl-header hgroup,.tmpl-header menu,.tmpl-header nav,.tmpl-header section {
  display:block;
}

.tmpl-header nav ul {
  list-style:none;
}

.tmpl-header blockquote, q {
  quotes:none;
}

.tmpl-header blockquote:before, .tmpl-header blockquote:after,
.tmpl-header q:before, .tmpl-header q:after {
  content:'';
  content:none;
}

.tmpl-header a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

/* change colours to suit your needs */
.tmpl-header ins {
  background-color:#ff9;
  color:#333;
  text-decoration:none;
}

/* change colours to suit your needs */
.tmpl-header mark {
  background-color:#ff9;
  color:#333;
  font-style:italic;
  font-weight:bold;
}

.tmpl-header del {
  text-decoration: line-through;
}

.tmpl-header abbr[title], .tmpl-header dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}

.tmpl-header table {
  border-collapse:collapse;
  border-spacing:0;
}

/* change border colour to suit your needs */
.tmpl-header hr {
  display:block;
  height:1px;
  border:0;
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}

.tmpl-header input, .tmpl-header select {
  vertical-align:middle;
}