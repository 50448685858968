#tmpl-header .tmpl-headerNavItem_label,
#tmpl-header .tmpl-headerNavItem_home {
  padding: 20px 13px;
}

#tmpl-header .tmpl-headerNavItem_home {
  font-weight: bold;
}


///* ----------------
//  DropDown
//---------------- */

@media screen and (min-width: 641px) {
  #tmpl-header .tmpl-headerNavDropDown {
    top: 56px;
  }
}